// Cerebro/ClientApp/src/components/DocumentViewer/DocumentViewer.js

import React, { useState, useEffect } from 'react';
import { X, Download, ZoomIn, ZoomOut, RotateCw } from 'lucide-react';
import './DocumentViewer.css';

const DocumentViewer = ({ document, onClose, onDownload }) => {
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [zoom, setZoom] = useState(100);
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        const loadDocument = async () => {
            try {
                if (!document?.blob) {
                    throw new Error('No document content available');
                }

                const contentType = document.contentType || document.ContentType;
                if (!contentType) {
                    throw new Error('Content type not available');
                }

                if (contentType.startsWith('image/') || contentType === 'application/pdf') {
                    setContent(URL.createObjectURL(document.blob));
                } else {
                    setError('This file type cannot be previewed in-browser.');
                }
            } catch (err) {
                setError('Error loading document');
                console.error('Document loading error:', err);
            } finally {
                setLoading(false);
            }
        };

        loadDocument();

        return () => {
            if (content) {
                URL.revokeObjectURL(content);
            }
        };
    }, [document]);

    const handleZoomIn = () => {
        setZoom((prev) => Math.min(prev + 25, 200));
    };

    const handleZoomOut = () => {
        setZoom((prev) => Math.max(prev - 25, 50));
    };

    const handleRotate = () => {
        setRotation((prev) => (prev + 90) % 360);
    };

    if (loading) {
        return (
            <div className="doc-viewer-overlay">
                <div className="doc-viewer-loading-container">
                    <div className="doc-viewer-loading-spinner" />
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="doc-viewer-overlay">
                <div className="doc-viewer-error-container">
                    <div className="doc-viewer-error-content">
                        <div className="doc-viewer-error-message">{error}</div>
                        <button
                            onClick={onDownload}
                            className="doc-viewer-download-btn"
                        >
                            Download Instead
                        </button>
                        <button
                            onClick={onClose}
                            className="doc-viewer-close-btn"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const contentType = document.contentType || document.ContentType;

    return (
        <div className="doc-viewer-overlay">
            <div className="doc-viewer-modal">
                <div className="doc-viewer-header">
                    <h3 className="doc-viewer-title">
                        {document.FileName}
                    </h3>
                    <div className="doc-viewer-controls">
                        {contentType.startsWith('image/') && (
                            <>
                                <button
                                    onClick={handleZoomIn}
                                    className="doc-viewer-control-btn"
                                    title="Zoom In"
                                >
                                    <ZoomIn className="doc-viewer-icon" />
                                </button>
                                <button
                                    onClick={handleZoomOut}
                                    className="doc-viewer-control-btn"
                                    title="Zoom Out"
                                >
                                    <ZoomOut className="doc-viewer-icon" />
                                </button>
                                <button
                                    onClick={handleRotate}
                                    className="doc-viewer-control-btn"
                                    title="Rotate"
                                >
                                    <RotateCw className="doc-viewer-icon" />
                                </button>
                            </>
                        )}
                        <button
                            onClick={onDownload}
                            className="doc-viewer-control-btn"
                            title="Download"
                        >
                            <Download className="doc-viewer-icon" />
                        </button>
                        <button
                            onClick={onClose}
                            className="doc-viewer-control-btn"
                            title="Close"
                        >
                            <X className="doc-viewer-icon" />
                        </button>
                    </div>
                </div>

                <div className="doc-viewer-content">
                    {contentType.startsWith('image/') ? (
                        <div className="doc-viewer-image-container">
                            <div className="doc-viewer-image-wrapper">
                                <img
                                    src={content}
                                    alt={document.FileName}
                                    className="doc-viewer-image"
                                    style={{
                                        transform: `scale(${zoom / 100}) rotate(${rotation}deg)`,
                                        transformOrigin: 'center center',
                                    }}
                                />
                            </div>
                        </div>
                    ) : contentType === 'application/pdf' ? (
                        <iframe
                            src={`${content}#toolbar=1&view=FitH`}
                            className="doc-viewer-pdf"
                            title={document.FileName}
                        />
                    ) : (
                        <div className="doc-viewer-unsupported">
                            <div className="doc-viewer-unsupported-content">
                                <p className="doc-viewer-message">
                                    This file type cannot be previewed in-browser
                                </p>
                                <button
                                    onClick={onDownload}
                                    className="doc-viewer-download-btn"
                                >
                                    Download Instead
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentViewer;
// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/VisitForm.js

import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import './VisitForm.css';

const VisitForm = ({ visit, onClose, onSave }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();

    const [loading, setLoading] = useState(false);
    const [template, setTemplate] = useState(null);

    /**
     * formData will always be in US units internally:
     *  - height (inches)
     *  - weight (pounds)
     *  - temperature (Fahrenheit)
     */
    const [formData, setFormData] = useState({
        visitId: visit?.visitId,
        status: 'InProgress',
        height: visit?.height || '',         // stored in inches
        weight: visit?.weight || '',         // stored in pounds
        bloodPressure: visit?.bloodPressure || '',
        temperature: visit?.temperature || '', // stored in °F
        heartRate: visit?.heartRate || '',
        responses: []
    });

    /**
     * displayValues are what the user sees in the UI. They might be in metric/imperial
     * depending on the toggles.
     */
    const [displayHeight, setDisplayHeight] = useState('');
    const [displayWeight, setDisplayWeight] = useState('');
    const [displayTemp, setDisplayTemp] = useState('');

    // Toggled units for display only
    const [heightUnit, setHeightUnit] = useState('in'); // 'in' or 'cm'
    const [weightUnit, setWeightUnit] = useState('lbs'); // 'lbs' or 'kg'
    const [tempUnit, setTempUnit] = useState('F');       // 'F' or 'C'

    // On component mount or when visit changes, fetch template, and sync display values
    useEffect(() => {
        if (visit?.visitTemplateId) {
            fetchTemplate();
        }

        // Initialize display values from the US-based formData
        setDisplayHeight(formData.height);    // initially in inches
        setDisplayWeight(formData.weight);    // initially in lbs
        setDisplayTemp(formData.temperature); // initially in F

        // If the visit has existing responses, populate them
        if (visit?.responses) {
            setFormData((prev) => ({
                ...prev,
                responses: visit.responses
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visit]);

    // If the user changes the toggles, convert the stored formData (in US units)
    // to display units (if needed)
    useEffect(() => {
        // Convert from inches to the displayHeight
        if (formData.height) {
            const inches = parseFloat(formData.height);
            if (!isNaN(inches)) {
                if (heightUnit === 'cm') {
                    setDisplayHeight((inches * 2.54).toFixed(2));
                } else {
                    setDisplayHeight(formData.height);
                }
            }
        } else {
            setDisplayHeight('');
        }
    }, [heightUnit, formData.height]);

    useEffect(() => {
        // Convert from lbs to the displayWeight
        if (formData.weight) {
            const lbs = parseFloat(formData.weight);
            if (!isNaN(lbs)) {
                if (weightUnit === 'kg') {
                    setDisplayWeight((lbs * 0.45359237).toFixed(2));
                } else {
                    setDisplayWeight(formData.weight);
                }
            }
        } else {
            setDisplayWeight('');
        }
    }, [weightUnit, formData.weight]);

    useEffect(() => {
        // Convert from Fahrenheit to the displayTemp
        if (formData.temperature) {
            const f = parseFloat(formData.temperature);
            if (!isNaN(f)) {
                if (tempUnit === 'C') {
                    setDisplayTemp(((f - 32) * (5 / 9)).toFixed(2));
                } else {
                    setDisplayTemp(formData.temperature);
                }
            }
        } else {
            setDisplayTemp('');
        }
    }, [tempUnit, formData.temperature]);

    const fetchTemplate = async () => {
        try {
            const response = await fetchWithAuth(`/api/visittemplate/${visit.visitTemplateId}`);
            if (response) {
                setTemplate(response);
                // If no responses yet, initialize them
                if (!formData.responses.length && response.fields) {
                    setFormData((prev) => ({
                        ...prev,
                        responses: response.fields.map((field) => ({
                            visitTemplateFieldId: field.visitTemplateFieldId,
                            response: '',
                            fieldLabel: field.label
                        }))
                    }));
                }
            }
        } catch (error) {
            showMessage('error', 'Error fetching visit template');
        }
    };

    /**
     * Whenever the user types in the "display" input, we convert it back
     * to US units for formData (which is stored in inches, lbs, °F).
     */
    const handleDisplayHeightChange = (value) => {
        setDisplayHeight(value);
        const numericVal = parseFloat(value);
        if (!isNaN(numericVal)) {
            if (heightUnit === 'cm') {
                // convert cm -> inches
                const inches = numericVal / 2.54;
                setFormData((prev) => ({ ...prev, height: inches.toFixed(2) }));
            } else {
                // store inches directly
                setFormData((prev) => ({ ...prev, height: numericVal.toString() }));
            }
        } else {
            // If invalid number, store empty or keep the old value
            setFormData((prev) => ({ ...prev, height: '' }));
        }
    };

    const handleDisplayWeightChange = (value) => {
        setDisplayWeight(value);
        const numericVal = parseFloat(value);
        if (!isNaN(numericVal)) {
            if (weightUnit === 'kg') {
                // convert kg -> lbs
                const lbs = numericVal / 0.45359237;
                setFormData((prev) => ({ ...prev, weight: lbs.toFixed(2) }));
            } else {
                // store lbs directly
                setFormData((prev) => ({ ...prev, weight: numericVal.toString() }));
            }
        } else {
            setFormData((prev) => ({ ...prev, weight: '' }));
        }
    };

    const handleDisplayTempChange = (value) => {
        setDisplayTemp(value);
        const numericVal = parseFloat(value);
        if (!isNaN(numericVal)) {
            if (tempUnit === 'C') {
                // convert °C -> °F
                const f = (numericVal * 9) / 5 + 32;
                setFormData((prev) => ({ ...prev, temperature: f.toFixed(2) }));
            } else {
                // store Fahrenheit directly
                setFormData((prev) => ({ ...prev, temperature: numericVal.toString() }));
            }
        } else {
            setFormData((prev) => ({ ...prev, temperature: '' }));
        }
    };

    /**
     * Toggle functions simply switch the displayed unit. We do NOT alter
     * formData here because it is always in US units (inches, lbs, F).
     */
    const toggleHeightUnit = () => {
        setHeightUnit((prev) => (prev === 'in' ? 'cm' : 'in'));
    };

    const toggleWeightUnit = () => {
        setWeightUnit((prev) => (prev === 'lbs' ? 'kg' : 'lbs'));
    };

    const toggleTempUnit = () => {
        setTempUnit((prev) => (prev === 'F' ? 'C' : 'F'));
    };

    // Handle input changes for other fields (blood pressure, heart rate, etc.)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle changes for dynamic template fields
    const handleResponseChange = (fieldId, value) => {
        setFormData((prev) => ({
            ...prev,
            responses: prev.responses.map((response) =>
                response.visitTemplateFieldId === fieldId
                    ? { ...response, response: value }
                    : response
            )
        }));
    };

    // Validate that the vitals and required fields are correct
    const validateForm = () => {
        const errors = [];

        // Because formData is stored in US units, these checks verify correct numeric input.
        if (formData.height && isNaN(parseFloat(formData.height))) {
            errors.push('Height must be a valid number');
        }
        if (formData.weight && isNaN(parseFloat(formData.weight))) {
            errors.push('Weight must be a valid number');
        }
        if (formData.heartRate && isNaN(parseInt(formData.heartRate, 10))) {
            errors.push('Heart rate must be a valid number');
        }
        if (formData.temperature && isNaN(parseFloat(formData.temperature))) {
            errors.push('Temperature must be a valid number');
        }

        if (template?.fields?.length) {
            template.fields.forEach((field) => {
                if (field.isRequired) {
                    const response = formData.responses.find(
                        (r) => r.visitTemplateFieldId === field.visitTemplateFieldId
                    );
                    if (!response || !response.response.trim()) {
                        errors.push(`${field.label} is required`);
                    }
                }
            });
        }

        if (errors.length) {
            errors.forEach((err) => showMessage('error', err));
            return false;
        }
        return true;
    };

    // Handler to "Save Progress"
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/visit/${visit.visitId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...visit,
                    ...formData,
                    status: 'InProgress'
                })
            });

            if (response) {
                showMessage('success', 'Visit updated successfully');
                onSave(response);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            showMessage('error', 'Error updating visit: ' + error.message);
        } finally {
            setLoading(false);
        }
    };


    // Handler to "Complete Visit"
    const handleComplete = async () => {
        if (!validateForm()) return;

        try {
            setLoading(true);
            const response = await fetchWithAuth(`/api/visit/${visit.visitId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ...visit,
                    ...formData,
                    status: 'Completed'
                })
            });

            if (response) {
                showMessage('success', 'Visit completed successfully');
                onSave(response);
                onClose();
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            showMessage('error', 'Error completing visit: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    // Rendering the vitals section (with separate display fields)
    const renderVitalsSection = () => (
        <div className="vf-form-section">
            <h3>Vitals</h3>
            <div className="vf-vitals-grid">
                {/* Height */}
                <div className="vf-form-group">
                    <label htmlFor="displayHeight">
                        Height ({heightUnit === 'in' ? 'inches' : 'cm'})
                    </label>
                    <div className="vf-input-toggle-container">
                        <input
                            type="number"
                            id="displayHeight"
                            name="displayHeight"
                            value={displayHeight}
                            onChange={(e) => handleDisplayHeightChange(e.target.value)}
                            placeholder={heightUnit === 'in' ? 'e.g., 72' : 'e.g., 183'}
                            step="0.01"
                        />
                        <button
                            type="button"
                            onClick={toggleHeightUnit}
                            className="vf-toggle-button"
                        >
                            {heightUnit === 'in' ? 'Switch to cm' : 'Switch to in'}
                        </button>
                    </div>
                </div>

                {/* Weight */}
                <div className="vf-form-group">
                    <label htmlFor="displayWeight">
                        Weight ({weightUnit === 'lbs' ? 'lbs' : 'kg'})
                    </label>
                    <div className="vf-input-toggle-container">
                        <input
                            type="number"
                            id="displayWeight"
                            name="displayWeight"
                            value={displayWeight}
                            onChange={(e) => handleDisplayWeightChange(e.target.value)}
                            placeholder={weightUnit === 'lbs' ? 'e.g., 180' : 'e.g., 81.65'}
                            step="0.01"
                        />
                        <button
                            type="button"
                            onClick={toggleWeightUnit}
                            className="vf-toggle-button"
                        >
                            {weightUnit === 'lbs' ? 'Switch to kg' : 'Switch to lbs'}
                        </button>
                    </div>
                </div>

                {/* Blood Pressure (always just text, no unit toggle) */}
                <div className="vf-form-group">
                    <label htmlFor="bloodPressure">Blood Pressure</label>
                    <input
                        type="text"
                        id="bloodPressure"
                        name="bloodPressure"
                        value={formData.bloodPressure}
                        onChange={handleInputChange}
                        placeholder="e.g., 120/80"
                    />
                </div>

                {/* Temperature */}
                <div className="vf-form-group">
                    <label htmlFor="displayTemp">
                        Temperature (°{tempUnit})
                    </label>
                    <div className="vf-input-toggle-container">
                        <input
                            type="number"
                            id="displayTemp"
                            name="displayTemp"
                            value={displayTemp}
                            onChange={(e) => handleDisplayTempChange(e.target.value)}
                            step="0.01"
                            placeholder={tempUnit === 'F' ? 'e.g., 98.6' : 'e.g., 37.0'}
                        />
                        <button
                            type="button"
                            onClick={toggleTempUnit}
                            className="vf-toggle-button"
                        >
                            {tempUnit === 'F' ? 'Switch to °C' : 'Switch to °F'}
                        </button>
                    </div>
                </div>

                {/* Heart Rate */}
                <div className="vf-form-group">
                    <label htmlFor="heartRate">Heart Rate (bpm)</label>
                    <input
                        type="number"
                        id="heartRate"
                        name="heartRate"
                        value={formData.heartRate}
                        onChange={handleInputChange}
                        step="1"
                        placeholder="e.g., 75"
                    />
                </div>
            </div>
        </div>
    );

    // Render the dynamic template fields, if any
    const renderTemplateFields = () => {
        if (!template) return null;

        return (
            <div className="vf-form-section">
                <h3>{template.name}</h3>
                <p className="vf-template-description">{template.description}</p>
                <div className="vf-template-fields">
                    {template.fields.map((field) => (
                        <div
                            key={field.visitTemplateFieldId}
                            className="vf-form-group"
                        >
                            <label htmlFor={`field_${field.visitTemplateFieldId}`}>
                                {field.label}
                                {field.isRequired && <span className="vf-required">*</span>}
                            </label>
                            {renderTemplateField(field)}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    // Handle the rendering of each template field type
    const renderTemplateField = (field) => {
        const response = formData.responses.find(
            (r) => r.visitTemplateFieldId === field.visitTemplateFieldId
        );
        const value = response ? response.response : '';

        switch (field.fieldType.toLowerCase()) {
            case 'text':
                return (
                    <input
                        type="text"
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) =>
                            handleResponseChange(field.visitTemplateFieldId, e.target.value)
                        }
                    />
                );
            case 'number':
                return (
                    <input
                        type="number"
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) =>
                            handleResponseChange(field.visitTemplateFieldId, e.target.value)
                        }
                    />
                );
            case 'textarea':
                return (
                    <textarea
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) =>
                            handleResponseChange(field.visitTemplateFieldId, e.target.value)
                        }
                        rows={4}
                    />
                );
            case 'select':
                return (
                    <select
                        id={`field_${field.visitTemplateFieldId}`}
                        value={value}
                        onChange={(e) =>
                            handleResponseChange(field.visitTemplateFieldId, e.target.value)
                        }
                    >
                        <option value="">Select...</option>
                        {field.options.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                );
            default:
                return null;
        }
    };

    return (
        <div className="vf-dialog-overlay">
            <div className="vf-visit-form-dialog">
                <div className="vf-dialog-header">
                    <h2>Visit Form</h2>
                    <button className="vf-close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="vf-visit-form">
                    {renderVitalsSection()}
                    {renderTemplateFields()}

                    <div className="vf-form-actions">
                        <button
                            type="button"
                            className="vf-cancel-button"
                            onClick={onClose}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="vf-save-button"
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save Progress'}
                        </button>
                        <button
                            type="button"
                            className="vf-complete-button"
                            onClick={handleComplete}
                            disabled={loading}
                        >
                            Complete Visit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VisitForm;

// Cerebro/ClientApp/src/pages/Dashboard/EHR/Dialog/EditPatient.js

import React, { useState, useEffect } from 'react';
import { Upload, FileText, Trash2, Download, Edit2 } from 'lucide-react';
import './AddPatient.css';
import backIcon from '../../../../assets/icons/Close.png';
import editIcon from '../../../../assets/icons/Edit.png';
import deleteIcon from '../../../../assets/icons/Delete.png';
import { useFetchWithAuth, uploadWithProgressAuth } from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import InputMask from 'react-input-mask';
import {
    formatPhoneNumber,
    stripPhoneFormatting,
    formatDateForDisplay,
    formatDateForApi
} from '../../../../utils/formatters';
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer';
import { formatDateTimeWithAt } from '../../../../utils/displayDateForTimeZone';

const EditPatient = ({ patient, onClose, onPatientUpdated, onPatientDeleted }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [auditLogs, setAuditLogs] = useState([]);
    const [activeTab, setActiveTab] = useState('demographics');
    const [errors, setErrors] = useState({});
    const [userRoles, setUserRoles] = useState(null);
    const [userProfiles, setUserProfiles] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [patientData, setPatientData] = useState({
        ...patient,
        DateOfBirth: formatDateForDisplay(patient.DateOfBirth),
        SexAssignedAtBirthId: patient.SexAssignedAtBirthId || '',
        GenderIdentityId: patient.GenderIdentityId || '',
        PreferredPronounId: patient.PreferredPronounId || '',
        StateId: patient.StateId || '',
        CountryId: patient.CountryId || '',
        Insurance: patient.Insurance || {}
    });
    const [isEditing, setIsEditing] = useState(false);
    const [originalInsuranceData, setOriginalInsuranceData] = useState(patient.Insurance || {});

    const [dropdownOptions, setDropdownOptions] = useState({
        sexAssignedAtBirth: [],
        genderIdentity: [],
        preferredPronouns: [],
        states: [],
        countries: [],
    });

    const [documents, setDocuments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [dragActive, setDragActive] = useState(false);
    const [renamingDocument, setRenamingDocument] = useState(null);
    const [newFileName, setNewFileName] = useState('');

    // The document the user wants to see in the popup
    const [selectedDocument, setSelectedDocument] = useState(null);
    // Whether or not the modal is open
    const [viewingDocument, setViewingDocument] = useState(false);

    // Fetch user profiles if needed
    useEffect(() => {
        const fetchUserProfiles = async () => {
            if (!auditLogs.length) return;

            const profiles = {};
            for (const log of auditLogs) {
                if (!userProfiles[log.UserId]) {
                    try {
                        const response = await fetchWithAuth(`/api/userprofiles/${log.UserId}`);
                        if (response) {
                            profiles[log.UserId] = response;
                        }
                    } catch (error) {
                        console.error('Error fetching user profile:', error);
                    }
                }
            }
            setUserProfiles(prevProfiles => ({ ...prevProfiles, ...profiles }));
        };

        if (activeTab === 'auditHistory') {
            fetchUserProfiles();
        }
    }, [auditLogs, activeTab, fetchWithAuth, userProfiles]);

    // Fetch user roles
    useEffect(() => {
        const fetchUserRoles = async () => {
            try {
                const response = await fetchWithAuth('/api/userroles');
                setUserRoles(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user roles:', error);
                setUserRoles(null);
                setIsLoading(false);
                showMessage('error', 'Error loading user permissions', 3000);
            }
        };

        fetchUserRoles();
    }, [fetchWithAuth, showMessage]);

    // Fetch dropdown options
    useEffect(() => {
        const fetchDropdownOptions = async () => {
            try {
                const [
                    sexAssignedAtBirthData,
                    genderIdentityData,
                    preferredPronounsData,
                    statesData,
                    countriesData,
                ] = await Promise.all([
                    fetchWithAuth('/api/patient/sexAssignedAtBirthOptions'),
                    fetchWithAuth('/api/patient/genderIdentityOptions'),
                    fetchWithAuth('/api/patient/preferredPronounOptions'),
                    fetchWithAuth('/api/patient/stateOptions'),
                    fetchWithAuth('/api/patient/countryOptions'),
                ]);

                const parseData = (data) => {
                    if (typeof data === 'object' && data !== null && '$values' in data) {
                        return data.$values;
                    }
                    if (Array.isArray(data)) {
                        return data;
                    }
                    console.error('Unexpected data format:', data);
                    return [];
                };

                setDropdownOptions({
                    sexAssignedAtBirth: parseData(sexAssignedAtBirthData),
                    genderIdentity: parseData(genderIdentityData),
                    preferredPronouns: parseData(preferredPronounsData),
                    states: parseData(statesData),
                    countries: parseData(countriesData),
                });
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
                setDropdownOptions({
                    sexAssignedAtBirth: [],
                    genderIdentity: [],
                    preferredPronouns: [],
                    states: [],
                    countries: [],
                });
            }
        };

        fetchDropdownOptions();
    }, [fetchWithAuth]);

    // Sync local patientData state whenever the main `patient` prop changes
    useEffect(() => {
        setPatientData({
            ...patient,
            DateOfBirth: patient.DateOfBirth ? formatDateForDisplay(patient.DateOfBirth) : null,
            PhoneNumber: patient.PhoneNumber ? formatPhoneNumber(patient.PhoneNumber) : '',
            EmergencyContactPhone: patient.EmergencyContactPhone
                ? formatPhoneNumber(patient.EmergencyContactPhone)
                : '',
            LegalAuthRepPhone: patient.LegalAuthRepPhone ? formatPhoneNumber(patient.LegalAuthRepPhone) : '',
            SexAssignedAtBirthId: patient.SexAssignedAtBirthId || '',
            GenderIdentityId: patient.GenderIdentityId || '',
            PreferredPronounId: patient.PreferredPronounId || '',
            StateId: patient.StateId || '',
            CountryId: patient.CountryId || '',
            Insurance: patient.Insurance || {}
        });
        setOriginalInsuranceData(patient.Insurance || {});
    }, [patient]);

    // Fetch documents if "Documents" tab is active
    useEffect(() => {
        if (activeTab === 'documents' && patientData.PatientId) {
            fetchDocuments();
        }
    }, [activeTab, patientData.PatientId]);

    const fetchDocuments = async () => {
        try {
            const response = await fetchWithAuth(`/api/patientdocument/patient/${patientData.PatientId}`);
            setDocuments(response.$values || []);
        } catch (error) {
            showMessage('error', 'Error fetching documents');
        }
    };

    // -- Document Functions -- //

    const handleDownload = async (documentId, fileName) => {
        try {
            const blob = await fetchWithAuth(`/api/patientdocument/download/${documentId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/octet-stream'
                }
            });

            if (!(blob instanceof Blob)) {
                throw new Error('Invalid response format');
            }
            if (blob.size === 0) {
                throw new Error('Downloaded file is empty');
            }

            const url = window.URL.createObjectURL(blob);
            try {
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } finally {
                window.URL.revokeObjectURL(url);
            }
            showMessage('success', `Download started for "${fileName}"`);
        } catch (error) {
            console.error('Download error:', error);
            showMessage('error', 'Error downloading document. Please try again.');
        }
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files[0]) {
            handleFiles(e.target.files);
        }
    };

    const handleFiles = async (files) => {
        if (!isEditing) {
            showMessage('error', 'Please enable editing to upload documents');
            return;
        }

        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);

        // Reset and show the progress bar
        setUploading(true);
        setUploadProgress(0);

        // Set up "fake" progress variables
        let fakeProgress = 0;
        let fakeProgressInterval = null;

        try {
            fakeProgressInterval = setInterval(() => {
                if (fakeProgress < 90) {
                    fakeProgress += 5; // Increase in 5% steps
                    setUploadProgress(fakeProgress);
                } else {
                    clearInterval(fakeProgressInterval);
                }
            }, 200); // every 200ms

            await uploadWithProgressAuth(
                `/api/patientdocument/upload/${patientData.PatientId}`,
                formData,
                () => {
                },
                'POST'
            );

            setUploadProgress(100);
            showMessage('success', 'Document uploaded successfully.');

            // Refresh the documents list
            fetchDocuments();
        } catch (error) {
            showMessage('error', error.message || 'Error uploading document.');
        } finally {
            // Clear interval and reset progress after a short delay
            if (fakeProgressInterval) {
                clearInterval(fakeProgressInterval);
            }
            setTimeout(() => {
                setUploading(false);
                setUploadProgress(0);
            }, 500);
        }
    };

    const formatFileSize = (bytes) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    };

    const handleRenameStart = (doc) => {
        setRenamingDocument(doc);
        setNewFileName(doc.FileName);
    };

    const handleRenameCancel = () => {
        setRenamingDocument(null);
        setNewFileName('');
    };

    const handleRenameSubmit = async (documentId) => {
        try {
            if (!newFileName || newFileName.trim() === '') {
                throw new Error('Filename cannot be empty');
            }
            const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
            if (invalidChars.test(newFileName)) {
                throw new Error('Filename contains invalid characters');
            }

            await fetchWithAuth(`/api/patientdocument/rename/${documentId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newFileName)
            });

            showMessage('success', 'Document renamed successfully.');
            setRenamingDocument(null);
            fetchDocuments();
        } catch (error) {
            console.error('Error renaming document:', error);
            const errorMessage = error.message || 'Error renaming document. Please try again.';
            showMessage('error', errorMessage);
        }
    };

    const handleDocumentDownloadInViewer = (doc) => {
        handleDownload(doc.DocumentId, doc.FileName);
    };

    const handleDocumentPreview = async (e, doc) => {
        // Prevent default browser behavior
        e.preventDefault();
        e.stopPropagation();

        try {
            const blob = await fetchWithAuth(`/api/patientdocument/download/${doc.DocumentId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/octet-stream'
                }
            });

            if (!(blob instanceof Blob)) {
                throw new Error('Invalid response format');
            }

            // Set document and open modal
            setSelectedDocument({
                ...doc,
                blob: blob,
                contentType: doc.ContentType
            });
            setViewingDocument(true);
            document.body.classList.add('modal-open');
        } catch (error) {
            showMessage('error', 'Error loading document preview');
            console.error('Preview error:', error);
        }
    };

    const handleCloseViewer = () => {
        setViewingDocument(false);
        setSelectedDocument(null);
        document.body.classList.remove('modal-open'); 
    };

    const fetchAuditLogs = React.useCallback(async () => {
        try {
            const response = await fetchWithAuth(`/api/patientaudit/${patientData.PatientId}`);
            const logs = response.$values || [];

            console.log('Raw logs from API:', logs);

            // Before sorting, get the display dates
            const logsWithDisplayDates = logs.map(log => ({
                ...log,
                displayDate: new Date(log.AccessDateTime + (!log.AccessDateTime.endsWith('Z') ? 'Z' : ''))
            }));

            console.log('Logs with display dates:', logsWithDisplayDates);

            // Sort by the actual timestamps
            const sortedLogs = [...logsWithDisplayDates].sort((a, b) =>
                b.displayDate.getTime() - a.displayDate.getTime()
            );

            console.log('Sorted logs:', sortedLogs);

            setAuditLogs(sortedLogs);
        } catch (error) {
            console.error('Error fetching audit logs:', error);
            showMessage('error', 'Error fetching audit history.', 3000);
        }
    }, [fetchWithAuth, patientData.PatientId, showMessage]);

    useEffect(() => {
        if (activeTab === 'auditHistory') {
            fetchAuditLogs();
        }
    }, [activeTab, fetchAuditLogs]);

    // Form field handling
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        if (name === 'DateOfBirth') {
            newValue = value.trim() === '' ? null : value;
        }

        if (name.startsWith('Insurance.')) {
            const insuranceField = name.split('.')[1];
            setPatientData(prevData => ({
                ...prevData,
                Insurance: {
                    ...prevData.Insurance,
                    [insuranceField]: newValue
                }
            }));
        } else {
            setPatientData(prevData => ({
                ...prevData,
                [name]: newValue
            }));
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const calculateAge = (dob) => {
        if (!dob) return '';
        const [month, day, year] = dob.split('/');
        const birthDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const isInsuranceDataChanged = () => {
        const fields = ['Carrier', 'Subscriber', 'Coverage', 'SubscriberId', 'Group', 'RxBIN'];
        return fields.some(field => {
            const originalValue = originalInsuranceData[field] || '';
            const currentValue = patientData.Insurance[field] || '';
            return originalValue !== currentValue;
        });
    };

    const isValidDate = (dateString) => {
        if (!dateString) return true; // Null/empty is valid
        const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
        if (!regex.test(dateString)) return false;

        const [month, day, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day
        );
    };

    const validateForm = () => {
        const newErrors = {};

        if (!patientData.FirstName?.trim()) newErrors.FirstName = 'First Name is required.';
        if (!patientData.LastName?.trim()) newErrors.LastName = 'Last Name is required.';

        const phoneRegex = /^\d{10}$/;
        if (patientData.PhoneNumber) {
            const strippedPhone = stripPhoneFormatting(patientData.PhoneNumber);
            if (!phoneRegex.test(strippedPhone)) {
                newErrors.PhoneNumber = 'Please enter a valid 10-digit phone number.';
            }
        }

        if (patientData.EmergencyContactPhone) {
            const strippedPhone = stripPhoneFormatting(patientData.EmergencyContactPhone);
            if (!phoneRegex.test(strippedPhone)) {
                newErrors.EmergencyContactPhone = 'Please enter a valid 10-digit phone number.';
            }
        }

        if (patientData.LegalAuthRepPhone) {
            const strippedPhone = stripPhoneFormatting(patientData.LegalAuthRepPhone);
            if (!phoneRegex.test(strippedPhone)) {
                newErrors.LegalAuthRepPhone = 'Please enter a valid 10-digit phone number.';
            }
        }

        if (patientData.EmailAddress && !isValidEmail(patientData.EmailAddress)) {
            newErrors.EmailAddress = 'Please enter a valid email address.';
        }

        if (patientData.DateOfBirth && !isValidDate(patientData.DateOfBirth)) {
            newErrors.DateOfBirth = 'Date of Birth must be a valid date.';
        }

        return newErrors;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Tab switching
    const handleTabSwitch = (tab) => {
        if (tab === 'auditHistory' && (!userRoles?.IsAdmin)) {
            showMessage('error', 'Access denied. Admin privileges required.', 3000);
            return;
        }
        setActiveTab(tab);
    };

    // Submitting the form
    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const patientDataToSend = { ...patientData };
                patientDataToSend.DateOfBirth = formatDateForApi(patientDataToSend.DateOfBirth);

                patientDataToSend.SexAssignedAtBirthId = Number(patientDataToSend.SexAssignedAtBirthId) || null;
                patientDataToSend.GenderIdentityId = Number(patientDataToSend.GenderIdentityId) || null;
                patientDataToSend.PreferredPronounId = Number(patientDataToSend.PreferredPronounId) || null;
                patientDataToSend.StateId = Number(patientDataToSend.StateId) || null;
                patientDataToSend.CountryId = Number(patientDataToSend.CountryId) || null;

                patientDataToSend.PhoneNumber = stripPhoneFormatting(patientDataToSend.PhoneNumber);
                patientDataToSend.EmergencyContactPhone = stripPhoneFormatting(patientDataToSend.EmergencyContactPhone);
                patientDataToSend.LegalAuthRepPhone = stripPhoneFormatting(patientDataToSend.LegalAuthRepPhone);

                patientDataToSend.InsuranceChanged = isInsuranceDataChanged();

                console.log(
                    'Data being sent to backend:',
                    JSON.stringify(patientDataToSend, null, 2)
                );

                const response = await fetchWithAuth(`/api/patient/${patientData.PatientId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(patientDataToSend)
                });

                if (response && response.PatientId) {
                    showMessage('success', 'Patient updated successfully.', 3000);
                    setIsEditing(false);
                    if (onPatientUpdated) {
                        onPatientUpdated(response);
                    }
                } else {
                    throw new Error('Invalid response from server');
                }
            } catch (error) {
                showMessage('error', 'Error updating patient. Please check your input and try again.', 0);
                console.error('Error updating patient:', error);
            }
        } else {
            const errorMessages = Object.values(validationErrors).join(' ');
            showMessage('error', errorMessages || 'Please correct the errors in the form.', 0);
        }
    };

    // Delete patient
    const handleDelete = () => {
        showMessage(
            'confirmation',
            'Are you sure you want to delete this patient?',
            0,
            0,
            async () => {
                try {
                    await fetchWithAuth(`/api/patient/${patientData.PatientId}`, {
                        method: 'DELETE'
                    });
                    showMessage('success', 'Patient deleted successfully.', 0, 0);
                    if (onPatientDeleted) {
                        onPatientDeleted(patientData.PatientId);
                    }
                    onClose();
                } catch (error) {
                    showMessage('error', 'Error deleting patient: ' + error.message, 0, 0);
                }
            }
        );
    };

    // Delete document
    const handleDocumentDelete = (e, documentId, fileName) => {
        e.preventDefault();
        e.stopPropagation();

        showMessage(
            'confirmation',
            `Are you sure you want to delete "${fileName}"?`,
            0,
            0,
            async () => {
                try {
                    await fetchWithAuth(`/api/patientdocument/${documentId}`, {
                        method: 'DELETE'
                    });
                    showMessage('success', 'Document deleted successfully.');
                    fetchDocuments();
                } catch (error) {
                    showMessage('error', 'Error deleting document: ' + (error.message || 'Unknown error'));
                }
            }
        );
    };

    // Toggle editing
    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    // Export to CSV
    const handleExportToCSV = () => {
        if (!auditLogs || auditLogs.length === 0) {
            showMessage('error', 'No audit logs available to export.', 3000);
            return;
        }

        const headers = ['User', 'Date/Time', 'Action', 'Details', 'Changes'];
        const rows = auditLogs.map(log => {
            let dateTimeString = log.AccessDateTime;
            if (!/Z$|[+-]\d{2}:\d{2}$/.test(dateTimeString)) {
                dateTimeString += 'Z';
            }
            const date = new Date(dateTimeString);
            const formattedDate = date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });

            const profile = userProfiles[log.UserId];
            const userDisplay = profile
                ? `${profile.FullName} (${profile.Username})`
                : log.Username || 'Unknown User';

            let changes = '';
            if (log.Changes) {
                try {
                    const changesObj = JSON.parse(log.Changes);
                    changes = Object.entries(changesObj)
                        .map(([field, values]) => {
                            const original = values.Original || '(empty)';
                            const updated = values.Updated || '(empty)';
                            return `${field}: From "${original}" to "${updated}"`;
                        })
                        .join('; ');
                } catch (e) {
                    console.error('Error parsing changes:', e);
                    changes = 'Error parsing changes';
                }
            }

            return [
                `"${userDisplay}"`,
                `"${formattedDate}"`,
                `"${log.ActionType}"`,
                `"${log.Details}"`,
                `"${changes}"`
            ].join(',');
        });

        const csvContent = [headers.join(','), ...rows].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `AuditLogs_Patient_${patientData.PatientId}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    // -- RENDERERS -- //

    const renderDemographics = () => (
        <div className="demographics-info">
            <div className="form-row">
                <label>
                    <span className="required-field">First Name:</span>
                    <input
                        type="text"
                        name="FirstName"
                        value={patientData.FirstName || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.FirstName ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.FirstName && <span className="error-message">{errors.FirstName}</span>}
                </label>
                <label>
                    Middle Name:
                    <input
                        type="text"
                        name="MiddleName"
                        value={patientData.MiddleName || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    <span className="required-field">Last Name:</span>
                    <input
                        type="text"
                        name="LastName"
                        value={patientData.LastName || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        className={errors.LastName ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.LastName && <span className="error-message">{errors.LastName}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Preferred Name:
                    <input
                        type="text"
                        name="PreferredName"
                        value={patientData.PreferredName || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Date of Birth:
                    <InputMask
                        mask="99/99/9999"
                        placeholder="mm/dd/yyyy"
                        name="DateOfBirth"
                        value={patientData.DateOfBirth || ''}
                        onChange={handleInputChange}
                        className={errors.DateOfBirth ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.DateOfBirth && <span className="error-message">{errors.DateOfBirth}</span>}
                </label>
                <label>
                    Age:
                    <input
                        type="text"
                        value={calculateAge(patientData.DateOfBirth)}
                        readOnly
                        disabled
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Sex Assigned at Birth:
                    <select
                        name="SexAssignedAtBirthId"
                        value={patientData.SexAssignedAtBirthId || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.sexAssignedAtBirth) &&
                            dropdownOptions.sexAssignedAtBirth.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Sex}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Gender Identity:
                    <select
                        name="GenderIdentityId"
                        value={patientData.GenderIdentityId || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.genderIdentity) &&
                            dropdownOptions.genderIdentity.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Gender}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Preferred Pronouns:
                    <select
                        name="PreferredPronounId"
                        value={patientData.PreferredPronounId || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.preferredPronouns) &&
                            dropdownOptions.preferredPronouns.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Pronoun}
                                </option>
                            ))}
                    </select>
                </label>
            </div>
        </div>
    );

    const renderContactInfo = () => (
        <div className="contact-info">
            <div className="form-row">
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.PhoneNumber || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'PhoneNumber',
                                    value: formattedPhone
                                }
                            });
                        }}
                        className={errors.PhoneNumber ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.PhoneNumber && <span className="error-message">{errors.PhoneNumber}</span>}
                </label>
                <label>
                    Email Address:
                    <input
                        type="email"
                        name="EmailAddress"
                        value={patientData.EmailAddress || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        className={errors.EmailAddress ? 'error' : ''}
                        disabled={!isEditing}
                    />
                    {errors.EmailAddress && <span className="error-message">{errors.EmailAddress}</span>}
                </label>
            </div>
            <div className="form-row">
                <label>
                    Street:
                    <input
                        type="text"
                        name="Street"
                        value={patientData.Street || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    City:
                    <input
                        type="text"
                        name="City"
                        value={patientData.City || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    State:
                    <select
                        name="StateId"
                        value={patientData.StateId || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.states) &&
                            dropdownOptions.states.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Name}
                                </option>
                            ))}
                    </select>
                </label>
                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="ZipCode"
                        value={patientData.ZipCode || ''}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Country:
                    <select
                        name="CountryId"
                        value={patientData.CountryId || ''}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(dropdownOptions.countries) &&
                            dropdownOptions.countries.map((option) => (
                                <option key={option.Id} value={option.Id}>
                                    {option.Name}
                                </option>
                            ))}
                    </select>
                </label>
            </div>
            <h4>Emergency Contact</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="EmergencyContactName"
                        value={patientData.EmergencyContactName || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="EmergencyContactRelationship"
                        value={patientData.EmergencyContactRelationship || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.EmergencyContactPhone || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'EmergencyContactPhone',
                                    value: formattedPhone
                                }
                            });
                        }}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <h4>Legally Authorized Representative</h4>
            <div className="form-row">
                <label>
                    Name:
                    <input
                        type="text"
                        name="LegalAuthRepName"
                        value={patientData.LegalAuthRepName || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Relationship:
                    <input
                        type="text"
                        name="LegalAuthRepRelationship"
                        value={patientData.LegalAuthRepRelationship || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Phone Number:
                    <InputMask
                        mask="(999) 999-9999"
                        value={patientData.LegalAuthRepPhone || ''}
                        onChange={(e) => {
                            const formattedPhone = stripPhoneFormatting(e.target.value);
                            handleInputChange({
                                target: {
                                    name: 'LegalAuthRepPhone',
                                    value: formattedPhone
                                }
                            });
                        }}
                        disabled={!isEditing}
                    />
                </label>
            </div>
        </div>
    );

    const renderInsurance = () => (
        <div className="insurance-info">
            <div className="form-row">
                <label>
                    Carrier:
                    <input
                        type="text"
                        name="Insurance.Carrier"
                        value={patientData.Insurance?.Carrier || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Subscriber:
                    <input
                        type="text"
                        name="Insurance.Subscriber"
                        value={patientData.Insurance?.Subscriber || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Coverage:
                    <input
                        type="text"
                        name="Insurance.Coverage"
                        value={patientData.Insurance?.Coverage || ''}
                        onChange={handleInputChange}
                        maxLength={100}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    Subscriber ID:
                    <input
                        type="text"
                        name="Insurance.SubscriberId"
                        value={patientData.Insurance?.SubscriberId || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
            </div>
            <div className="form-row">
                <label>
                    Group:
                    <input
                        type="text"
                        name="Insurance.Group"
                        value={patientData.Insurance?.Group || ''}
                        onChange={handleInputChange}
                        maxLength={50}
                        disabled={!isEditing}
                    />
                </label>
                <label>
                    RxBIN:
                    <input
                        type="text"
                        name="Insurance.RxBIN"
                        value={patientData.Insurance?.RxBIN || ''}
                        onChange={handleInputChange}
                        maxLength={20}
                        disabled={!isEditing}
                    />
                </label>
            </div>
        </div>
    );

    const renderDocuments = () => (
        <div className="document-tab">
            <div
                className={`document-upload ${dragActive ? 'drag-active' : ''} ${!isEditing ? 'disabled' : ''
                    }`}
                onDragEnter={isEditing ? handleDrag : undefined}
                onDragLeave={isEditing ? handleDrag : undefined}
                onDragOver={isEditing ? handleDrag : undefined}
                onDrop={isEditing ? handleDrop : undefined}
            >
                <input
                    type="file"
                    id="file-upload"
                    className="file-input"
                    onChange={handleFileSelect}
                    disabled={!isEditing || uploading}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                />
                <label htmlFor="file-upload" className="upload-label">
                    <Upload size={24} />
                    <span>
                        {uploading
                            ? 'Uploading...'
                            : isEditing
                                ? 'Drag and drop a file here or click to select'
                                : 'Enter edit mode to upload documents'}
                    </span>
                </label>
            </div>

            {uploading && (
                <div className="upload-progress">
                    <progress value={uploadProgress} max="100" />
                    <span>{uploadProgress}%</span>
                </div>
            )}

            <div className="documents-list">
                {documents.length === 0 ? (
                    <div className="no-documents">
                        <FileText size={48} />
                        <p>No documents uploaded yet</p>
                    </div>
                ) : (
                    <table className="documents-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Size</th>
                                <th>Uploaded By</th>
                                <th>Upload Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((doc) => (
                                <tr key={doc.DocumentId}>
                                    <td>
                                        {renamingDocument?.DocumentId === doc.DocumentId ? (
                                            <div className="rename-form">
                                                <input
                                                    type="text"
                                                    value={newFileName}
                                                    onChange={(e) => setNewFileName(e.target.value)}
                                                    className="rename-input"
                                                />
                                                <div className="rename-actions">
                                                    <button
                                                        onClick={() => handleRenameSubmit(doc.DocumentId)}
                                                        className="action-button confirm"
                                                        title="Save"
                                                        type="button"
                                                    >
                                                        ✓
                                                    </button>
                                                    <button
                                                        onClick={handleRenameCancel}
                                                        className="action-button cancel"
                                                        title="Cancel"
                                                        type="button"
                                                    >
                                                        ✕
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={(e) => handleDocumentPreview(e, doc)}
                                                className="document-name-button"
                                                title="Click to preview document"
                                                type="button"
                                            >
                                                {doc.FileName}
                                            </button>
                                        )}
                                    </td>
                                    <td>{formatFileSize(doc.FileSize)}</td>
                                    <td>{doc.UploadedByUserName}</td>
                                    <td>{formatDateTimeWithAt(doc.UploadDate, true)}</td>
                                    <td>
                                        {!renamingDocument && (
                                            <div className="document-actions">
                                                <button
                                                    onClick={() => handleDownload(doc.DocumentId, doc.FileName)}
                                                    className="action-button download"
                                                    title="Download"
                                                    type="button"
                                                >
                                                    <Download size={16} />
                                                </button>
                                                {isEditing && (
                                                    <>
                                                        <button
                                                            onClick={() => handleRenameStart(doc)}
                                                            className="action-button rename"
                                                            title="Rename"
                                                            type="button"
                                                        >
                                                            <Edit2 size={16} />
                                                        </button>
                                                        <button
                                                            onClick={(e) =>
                                                                handleDocumentDelete(e, doc.DocumentId, doc.FileName)
                                                            }
                                                            className="action-button delete"
                                                            title="Delete"
                                                            type="button"
                                                        >
                                                            <Trash2 size={16} />
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );


    const renderAuditHistory = () => {
        const getUserDisplay = (log) => {
            const profile = userProfiles[log.UserId];
            if (profile) {
                return `${profile.FullName} (${profile.Username})`;
            }
            return log.Username || 'Unknown User';
        };

        const formatDateTime = (dateTimeString) => {
            if (!/Z$|[+-]\d{2}:\d{2}$/.test(dateTimeString)) {
                dateTimeString += 'Z';
            }
            const date = new Date(dateTimeString);
            return date.toLocaleString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            });
        };

        const formatChanges = (changesString) => {
            if (!changesString) return null;
            try {
                const changes = JSON.parse(changesString);
                return (
                    <div className="changes-container">
                        {Object.entries(changes).map(([field, values]) => (
                            <div key={field} className="change-item">
                                <div className="field-name">{field}</div>
                                <div className="change-values">
                                    <div className="original-value">
                                        From:{' '}
                                        <span>
                                            {values.Original && typeof values.Original === 'object'
                                                ? values.Original.Value
                                                : values.Original || '(empty)'}
                                        </span>
                                    </div>
                                    <div className="updated-value">
                                        To:{' '}
                                        <span>
                                            {values.Updated && typeof values.Updated === 'object'
                                                ? values.Updated.Value
                                                : values.Updated || '(empty)'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            } catch (e) {
                console.error('Error parsing changes:', e);
                return <div className="error-message">Error displaying changes</div>;
            }
        };

        if (auditLogs.length === 0) {
            return (
                <div className="no-audit-logs">
                    <p>No audit history available</p>
                </div>
            );
        }

        return (
            <div className="audit-history">
                <div className="export-button-container">
                    <button type="button" className="export-button" onClick={handleExportToCSV}>
                        Export to CSV
                    </button>
                </div>
                <table className="audit-table">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Date/Time</th>
                            <th>Action</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLogs.map((log) => (
                            <tr key={log.AuditId}>
                                <td>{getUserDisplay(log)}</td>
                                <td>{formatDateTimeWithAt(log.AccessDateTime, true)}</td>
                                <td>
                                    <span className={`action-type ${log.ActionType.toLowerCase()}`}>
                                        {log.ActionType}
                                    </span>
                                </td>
                                <td>
                                    <div className="log-details">
                                        {log.Details}
                                        {log.Changes && (
                                            <details className="changes-details">
                                                <summary>View Changes</summary>
                                                {formatChanges(log.Changes)}
                                            </details>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderTabs = () => (
        <div className="tabs">
            <button
                className={activeTab === 'demographics' ? 'active' : ''}
                onClick={() => handleTabSwitch('demographics')}
            >
                Demographics
            </button>
            <button
                className={activeTab === 'contactInfo' ? 'active' : ''}
                onClick={() => handleTabSwitch('contactInfo')}
            >
                Contact Info
            </button>
            <button
                className={activeTab === 'insurance' ? 'active' : ''}
                onClick={() => handleTabSwitch('insurance')}
            >
                Insurance
            </button>
            <button
                className={activeTab === 'documents' ? 'active' : ''}
                onClick={() => handleTabSwitch('documents')}
            >
                Documents
            </button>
            {userRoles?.IsAdmin && (
                <button
                    className={activeTab === 'auditHistory' ? 'active' : ''}
                    onClick={() => handleTabSwitch('auditHistory')}
                >
                    Access History
                </button>
            )}
        </div>
    );

    if (isLoading) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <>
            {/* Main Edit Patient layout */}
            <div className="add-patient-form">
                <div className="form-header">
                    <div className="edit-actions">
                        {isEditing ? (
                            <img src={backIcon} alt="Close" onClick={handleEditToggle} />
                        ) : (
                            <img src={editIcon} alt="Edit" onClick={handleEditToggle} />
                        )}
                        <img src={deleteIcon} alt="Delete" onClick={handleDelete} />
                    </div>
                    <h2>Edit Patient</h2>
                    <img src={backIcon} alt="Close" className="back-icon" onClick={onClose} />
                </div>

                {renderTabs()}

                <form onSubmit={handleSubmit}>
                    <div className="tab-content">
                        {activeTab === 'demographics' && renderDemographics()}
                        {activeTab === 'contactInfo' && renderContactInfo()}
                        {activeTab === 'insurance' && renderInsurance()}
                        {activeTab === 'documents' && renderDocuments()}
                        {activeTab === 'auditHistory' && userRoles?.IsAdmin && renderAuditHistory()}
                    </div>

                    {isEditing && (
                        <div className="form-buttons">
                            <button type="submit">Save Changes</button>
                        </div>
                    )}
                </form>
            </div>

            {/* Render the popup at the top level so it truly appears as a modal */}
            {viewingDocument && selectedDocument && (
                <DocumentViewer
                    document={selectedDocument}
                    onClose={handleCloseViewer}
                    onDownload={() => handleDocumentDownloadInViewer(selectedDocument)}
                />
            )}
        </>
    );
};

export default EditPatient;

// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/AddVisitDialog.js

import React, { useState, useEffect } from 'react';
import { Plus, X, ArrowRightCircle } from 'lucide-react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import PatientSearch from './PatientSearch';
import AddPatient from '../../EHR/Dialog/AddPatient';
import VisitForm from './VisitForm';
import './AddVisitDialog.css';

const AddVisitDialog = ({ visit, onClose, onSave, selectedDate, isAdmin }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [searchMode, setSearchMode] = useState('patient');
    const [templates, setTemplates] = useState([]);
    const [showVisitForm, setShowVisitForm] = useState(false);

    /**
     * Round date to the nearest 15-minute interval.
     */
    const roundToNearest15 = (date) => {
        const copy = new Date(date.getTime());
        const totalMinutes = copy.getHours() * 60 + copy.getMinutes() + copy.getSeconds() / 60;
        const rounded = Math.round(totalMinutes / 15) * 15;
        const hours = Math.floor(rounded / 60);
        const minutes = rounded % 60;
        copy.setHours(hours, minutes, 0, 0);
        return copy;
    };

    /**
     * Create a list of time options from 07:00 to 19:00 in 15-min increments.
     */
    const generateTimeOptions = () => {
        const times = [];
        for (let h = 7; h <= 19; h++) {
            for (let m = 0; m < 60; m += 15) {
                if (h === 19 && m > 0) break;
                const hh = String(h).padStart(2, '0');
                const mm = String(m).padStart(2, '0');
                times.push(`${hh}:${mm}`);
            }
        }
        return times;
    };

    const timeOptions = generateTimeOptions();

    // If a valid date was passed in, use it; otherwise, default to now.
    // Then round to the nearest 15 minutes.
    let initialDate = selectedDate && !isNaN(new Date(selectedDate))
        ? new Date(selectedDate)
        : new Date();
    initialDate = roundToNearest15(initialDate);

    const [formData, setFormData] = useState({
        scheduledDateTime: initialDate,
        durationMinutes: 30,
        status: 'Scheduled',
        notes: '',
        visitTemplateId: '',
        height: '',
        weight: '',
        bloodPressure: '',
        temperature: '',
        heartRate: '',
        responses: []
    });

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [showAddPatient, setShowAddPatient] = useState(false);
    const [loading, setLoading] = useState(false);
    const [localTimeValue, setLocalTimeValue] = useState(
        (() => {
            const hours = String(initialDate.getHours()).padStart(2, '0');
            const mins = String(initialDate.getMinutes()).padStart(2, '0');
            return `${hours}:${mins}`;
        })()
    );

    useEffect(() => {
        if (visit) {
            setFormData({
                ...visit,
                scheduledDateTime: new Date(visit.scheduledDateTime)
            });
            if (visit.patientId) {
                setSelectedEntity({
                    type: 'patient',
                    data: {
                        PatientId: visit.patientId,
                        FirstName: visit.patientName?.split(' ')[0] || '',
                        LastName: visit.patientName?.split(' ')[1] || ''
                    }
                });
            } else if (visit.subjectId) {
                setSelectedEntity({
                    type: 'subject',
                    data: {
                        SubjectId: visit.subjectId,
                        SubjectNumber: visit.subjectNumber,
                        ProtocolNumber: visit.protocolNumber
                    }
                });
            }
        }
        fetchTemplates();
    }, [visit]);

    const fetchTemplates = async () => {
        try {
            const response = await fetchWithAuth('/api/visittemplate/company/1');
            setTemplates(response.$values || []);
        } catch (error) {
            showMessage('error', 'Error fetching visit templates');
        }
    };

    const handleSearchModeChange = (mode) => {
        setSearchMode(mode);
        setSelectedEntity(null);
    };

    const formatTo12Hour = (time24) => {
        const [hoursStr, minutesStr] = time24.split(':');
        let hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        return `${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    };

    const handleEntitySelect = (entity) => {
        setSelectedEntity(entity);
    };

    const handleShowAddPatient = () => {
        setShowAddPatient(true);
    };

    const handlePatientAdded = (newPatient) => {
        setShowAddPatient(false);
        setSelectedEntity({
            type: 'patient',
            data: newPatient
        });
    };

    const handleDateTimeChange = (e) => {
        const { name, value } = e.target;
        let currentDateTime = formData.scheduledDateTime instanceof Date && !isNaN(formData.scheduledDateTime)
            ? new Date(formData.scheduledDateTime)
            : new Date();

        if (name === 'date') {
            const [y, m, d] = value.split('-').map(Number);
            const newDateTime = new Date(
                y,
                m - 1,
                d,
                currentDateTime.getHours(),
                currentDateTime.getMinutes(),
                0,
                0
            );
            setFormData(prev => ({
                ...prev,
                scheduledDateTime: newDateTime
            }));
        } else if (name === 'time') {
            setLocalTimeValue(value);
            const [h, min] = value.split(':').map(Number);
            const newDateTime = new Date(
                currentDateTime.getFullYear(),
                currentDateTime.getMonth(),
                currentDateTime.getDate(),
                h,
                min,
                0,
                0
            );
            setFormData(prev => ({
                ...prev,
                scheduledDateTime: newDateTime
            }));
        }
    };

    const handleStartVisit = () => {
        setShowVisitForm(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!selectedEntity) {
            showMessage('error', 'Please select a patient or subject');
            return false;
        }
        if (!formData.scheduledDateTime) {
            showMessage('error', 'Please select a date and time');
            return false;
        }
        if (!formData.durationMinutes || formData.durationMinutes < 15) {
            showMessage('error', 'Duration must be at least 15 minutes');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setLoading(true);
            const localDateTime = formData.scheduledDateTime;
            const utcDateTime = new Date(
                localDateTime.getTime() - localDateTime.getTimezoneOffset() * 60_000
            );

            const visitData = {
                ...visit,
                scheduledDateTime: utcDateTime.toISOString(),
                durationMinutes: parseInt(formData.durationMinutes, 10),
                notes: formData.notes || '',
                visitTemplateId: formData.visitTemplateId || null,
                templateName: formData.visitTemplateId
                    ? templates.find(t => t.visitTemplateId === formData.visitTemplateId)?.name
                    : null
            };

            if (selectedEntity.type === 'patient') {
                visitData.patientId = selectedEntity.data.PatientId;
                visitData.patientName = `${selectedEntity.data.FirstName} ${selectedEntity.data.LastName}`;
            } else {
                visitData.subjectId = selectedEntity.data.SubjectId;
                visitData.subjectNumber = selectedEntity.data.SubjectNumber;
            }

            const method = visit ? 'PUT' : 'POST';
            const url = visit ? `/api/visit/${visit.visitId}` : '/api/visit';

            const response = await fetchWithAuth(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(visitData),
            });

            if (response && (response.VisitId || response.visitId)) {
                showMessage('success', `Visit ${visit ? 'updated' : 'scheduled'} successfully.`);
                onSave(response);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (error) {
            showMessage('error', `Error ${visit ? 'updating' : 'scheduling'} visit: ${error.message || 'Unknown error'}`);
        } finally {
            setLoading(false);
        }
    };

    const handleVisitSave = (updatedVisit) => {
        if (visit) {
            // Update the internal visit data without closing
            visit = { ...visit, ...updatedVisit };
        }
    };

    const handleVisitComplete = (updatedVisit) => {
        onSave(updatedVisit); // Update and then close
        setShowVisitForm(false);
    };

    const canAccessVisitForm = () => {
        if (!isAdmin) return false;
        if (!visit) return false;
        return visit.status === 'Scheduled' || visit.status === 'InProgress';
    };

    const canUpdateVisit = () => {
        if (!visit) return true; // New visits can always be created
        return visit.status !== 'Completed' && visit.status !== 'Cancelled';
    };

    const canShowCancelButton = () => {
        if (!visit) return true; // New visit - show cancel
        return visit.status === 'Scheduled'; // Only show for Scheduled visits
    };

    const getVisitButtonText = () => {
        if (!visit) return '';
        return visit.status === 'Scheduled' ? 'Start Visit' : 'Enter Visit';
    };

    const handleVisitAccess = () => {
        setShowVisitForm(true);
    };

    if (showVisitForm) {
        return (
            <VisitForm
                visit={visit}
                onClose={() => setShowVisitForm(false)}
                onSave={handleVisitSave}
                onComplete={handleVisitComplete}
            />
        );
    }

    return (
        <div className="dialog-overlay">
            <div className="add-visit-dialog">
                <div className="dialog-header">
                    <h2>{visit ? 'Edit Visit' : 'Schedule New Visit'}</h2>
                    <button className="close-button" onClick={onClose}>
                        <X size={24} />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="dialog-content">
                    <div className="add-visit-form-section patient-info-section">
                        <h3>
                            {selectedEntity?.type === 'subject'
                                ? 'Subject Information'
                                : 'Patient Information'}
                        </h3>
                        {!selectedEntity ? (
                            <div className="patient-search-container">
                                <PatientSearch
                                    onSelect={handleEntitySelect}
                                    onModeChange={handleSearchModeChange}
                                />
                            </div>
                        ) : selectedEntity.type === 'patient' ? (
                            <div className="selected-patient">
                                <div className="patient-info">
                                    <span className="patient-name">
                                        {selectedEntity.data.FirstName}{' '}
                                        {selectedEntity.data.LastName}
                                    </span>
                                    <button
                                        type="button"
                                        className="change-button"
                                        onClick={() => setSelectedEntity(null)}
                                    >
                                        Change
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="selected-subject">
                                <div className="subject-info">
                                    <div className="subject-details">
                                        <span className="subject-number">
                                            Subject Number: {selectedEntity.data.SubjectNumber}
                                        </span>
                                        {selectedEntity.data.ProtocolNumber && (
                                            <span className="subject-protocol">
                                                Protocol Number: {selectedEntity.data.ProtocolNumber}
                                            </span>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        className="change-button"
                                        onClick={() => setSelectedEntity(null)}
                                    >
                                        Change
                                    </button>
                                </div>
                            </div>
                        )}
                        {(searchMode === 'patient' && !selectedEntity) ||
                            selectedEntity?.type === 'patient' ? (
                            <button
                                type="button"
                                className="add-patient-for-visit-button"
                                onClick={handleShowAddPatient}
                            >
                                <Plus size={20} />
                                Add New Patient
                            </button>
                        ) : null}
                    </div>

                    <div className="add-visit-form-section">
                        <h3>Visit Details</h3>
                        <div className="add-visit-form-row">
                            <div className="add-visit-form-group">
                                <label htmlFor="date">Date</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    value={formData.scheduledDateTime.toLocaleDateString('en-CA')}
                                    onChange={handleDateTimeChange}
                                />
                            </div>
                            <div className="add-visit-form-group">
                                <label htmlFor="time">Time</label>
                                <select
                                    id="time"
                                    name="time"
                                    value={localTimeValue}
                                    onChange={handleDateTimeChange}
                                >
                                    {timeOptions.map((t) => (
                                        <option key={t} value={t}>
                                            {formatTo12Hour(t)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="add-visit-form-group">
                                <label htmlFor="durationMinutes">Duration (minutes)</label>
                                <select
                                    id="durationMinutes"
                                    name="durationMinutes"
                                    value={formData.durationMinutes}
                                    onChange={handleInputChange}
                                >
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                    <option value={60}>60</option>
                                    <option value={90}>90</option>
                                    <option value={120}>120</option>
                                </select>
                            </div>
                        </div>

                        <div className="add-visit-form-group">
                            <label htmlFor="visitTemplateId">Visit Template</label>
                            <select
                                id="visitTemplateId"
                                name="visitTemplateId"
                                value={formData.visitTemplateId || ''}
                                onChange={handleInputChange}
                            >
                                <option value="">Select Template...</option>
                                {templates.map((template) => (
                                    <option
                                        key={template.visitTemplateId}
                                        value={template.visitTemplateId}
                                    >
                                        {template.name}
                                    </option>
                                ))}
                            </select>
                            {formData.visitTemplateId && (
                                <div className="template-description">
                                    {templates.find(t => t.visitTemplateId === formData.visitTemplateId)?.description}
                                </div>
                            )}
                        </div>

                        <div className="add-visit-form-group">
                            <label htmlFor="notes">Notes</label>
                            <textarea
                                id="notes"
                                name="notes"
                                value={formData.notes}
                                onChange={handleInputChange}
                                rows={4}
                            />
                        </div>
                    </div>

                    <div className="dialog-actions">
                        {canAccessVisitForm() && (
                            <button
                                type="button"
                                className="start-visit-button"
                                onClick={handleVisitAccess}
                            >
                                <ArrowRightCircle size={20} />
                                {getVisitButtonText()}
                            </button>
                        )}
                        {canUpdateVisit() && (
                            <>
                                {canShowCancelButton() && (
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={onClose}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                )}
                                <button
                                    type="submit"
                                    className="save-button"
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : visit ? 'Update Visit' : 'Schedule Visit'}
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </div>

            {showAddPatient && (
                <div className="modal-overlay">
                    <AddPatient
                        onClose={() => setShowAddPatient(false)}
                        onSave={handlePatientAdded}
                    />
                </div>
            )}
        </div>
    );
};

export default AddVisitDialog;
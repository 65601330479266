// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/MonthView.js

import React, { useState } from 'react';
import {
    format,
    startOfMonth,
    endOfMonth,
    eachDayOfInterval,
    isSameMonth,
    startOfWeek,
    endOfWeek,
    isToday,
    isWeekend,
    getWeeksInMonth
} from 'date-fns';
import { Clock } from 'lucide-react';

const MonthView = ({ currentDate, visits, onVisitClick, onDateClick }) => {
    // Holds the dateKey (yyyy-MM-dd) for the popup that is currently open
    const [openPopupDate, setOpenPopupDate] = useState(null);

    // Calculate month range
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const calendarStart = startOfWeek(monthStart);
    const calendarEnd = endOfWeek(monthEnd);
    const weeksInMonth = getWeeksInMonth(currentDate);

    // Days of the week (header labels)
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // All days to display in the grid
    const calendarDays = eachDayOfInterval({ start: calendarStart, end: calendarEnd });

    // Group visits by date (YYYY-MM-DD)
    const visitsByDate = visits.reduce((acc, visit) => {
        const dateKey = format(new Date(visit.scheduledDateTime), 'yyyy-MM-dd');
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(visit);
        return acc;
    }, {});

    // Returns the visits for a given date
    const getVisitsForDate = (date) => {
        const dateKey = format(date, 'yyyy-MM-dd');
        return (visitsByDate[dateKey] || [])
            .sort((a, b) => new Date(a.scheduledDateTime) - new Date(b.scheduledDateTime));
    };

    // Single visit item rendering (used in date cell and popup)
    const renderVisit = (visit) => {
        // Get the display name based on whether it's a patient or subject
        const getDisplayName = () => {

            if (visit.patientName && visit.patientName.trim()) {
                return visit.patientName;
            } else if (visit.subjectNumber) {
                return visit.protocolNumber
                    ? `${visit.subjectNumber}/${visit.protocolNumber}`
                    : visit.subjectNumber;
            }
            return 'Unnamed Visit';
        };

        return (
            <div
                key={visit.visitId}
                className={`month-visit status-${visit.status.toLowerCase()}`}
                onClick={(e) => {
                    e.stopPropagation();       
                    onVisitClick(visit);
                }}
            >
                <div className="month-visit-content">
                    <div className="month-visit-info">
                        <span className="month-visit-time">
                            <Clock size={12} className="visit-icon" />
                            {format(new Date(visit.scheduledDateTime), 'h:mm a')}
                        </span>
                        <span className="month-visit-name">{getDisplayName()}</span>
                    </div>
                    <span className={`status-indicator status-${visit.status.toLowerCase()}`} />
                </div>
            </div>
        );
    };

    // Popup component for displaying all visits
    const VisitListPopup = ({ visits, isLastRow, alignment }) => {
        // If it's in the last row, show popup above instead of below
        const position = isLastRow ? 'top' : 'bottom';

        return (
            <div className={`visit-list-popup ${position} ${alignment}`}>
                <div className="visit-list-popup-header">
                    {format(new Date(visits[0]?.scheduledDateTime), 'MMMM d, yyyy')}
                </div>
                <div className="visit-list-popup-content">
                    {visits.map(renderVisit)}
                </div>
            </div>
        );
    };

    // Renders each date in the calendar
    const renderDate = (date) => {
        const dateVisits = getVisitsForDate(date);
        const isCurrentMonth = isSameMonth(date, currentDate);
        const isWeekendDay = isWeekend(date);
        const dateKey = format(date, 'yyyy-MM-dd');
        const isPopupOpen = openPopupDate === dateKey;

        // Determine which column this date cell is in, i.e. colIndex in [0..6].
        const dayIndex = calendarDays.indexOf(date);
        const colIndex = dayIndex % 7;

        // For leftmost column => align-left, for rightmost column => align-right, else align-center
        let alignmentClass = 'align-center';
        if (colIndex === 0) alignmentClass = 'align-left';
        if (colIndex === 6) alignmentClass = 'align-right';

        const className = [
            'calendar-date',
            !isCurrentMonth && 'other-month',
            isToday(date) && 'today',
            isWeekendDay && 'weekend'
        ]
            .filter(Boolean)
            .join(' ');

        // Sort by scheduled time to find the earliest (most upcoming)
        const firstVisit = dateVisits[0];

        return (
            <div
                key={date.toString()}
                className={className}
                onClick={() => onDateClick(date)}
                // If the mouse leaves this entire date cell, close the popup
                onMouseLeave={() => setOpenPopupDate(null)}
            >
                {/* Header with day # and visit-count */}
                <div className="date-header">
                    <div className="date-number-container">
                        <span className="date-number">{format(date, 'd')}</span>
                    </div>

                    {dateVisits.length > 0 && (
                        <div
                            className="visit-count-container"
                            style={{ position: 'relative' }}
                            // Only open (or keep open) when hovering over the visit count
                            onMouseEnter={() => setOpenPopupDate(dateKey)}
                        >
                            <span className="visit-count">
                                {dateVisits.length}{' '}
                                {dateVisits.length === 1 ? 'visit' : 'visits'}
                            </span>

                            {/* Show popup if open */}
                            {isPopupOpen && (
                                // Wrap the actual popup so that hovering over it keeps it open
                                <div
                                    onMouseEnter={() => setOpenPopupDate(dateKey)}
                                    onMouseLeave={() => setOpenPopupDate(null)}
                                >
                                    <VisitListPopup
                                        visits={dateVisits}
                                        isLastRow={
                                            Math.floor(calendarDays.indexOf(date) / 7) ===
                                            Math.floor(calendarDays.length / 7 - 1)
                                        }
                                        alignment={alignmentClass}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Container for the single earliest visit */}
                <div className="month-visits-container">
                    {firstVisit && renderVisit(firstVisit)}
                </div>
            </div>
        );
    };

    return (
        <div className="month-view">
            {/* Days of week header */}
            <div className="calendar-header">
                {daysOfWeek.map((day) => (
                    <div key={day} className="weekday-header">
                        <span className="weekday-full">{day}</span>
                        <span className="weekday-short">{day.slice(0, 3)}</span>
                    </div>
                ))}
            </div>

            {/* Grid of days */}
            <div className="month-content">
                <div className={`calendar-grid weeks-${weeksInMonth}`}>
                    {calendarDays.map((date) => renderDate(date))}
                </div>
            </div>
        </div>
    );
};

export default MonthView;

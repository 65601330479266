// Cerebro/ClientApp/src/utils/displayDateForTimeZone.js

/**
 * Determines if a date string should be treated as UTC based on context
 * @param {Object} auditLog - The audit log entry
 * @returns {boolean} Whether the date should be treated as UTC
 */
const shouldTreatAsUtc = (auditLog) => {
    if (!auditLog?.Details) return false;

    const details = auditLog.Details.toLowerCase();

    // Check for document-related actions
    if (details.includes('uploaded document:') ||
        details.includes('soft deleted document:')) {
        return true;
    }

    return false;
};

/**
 * Formats a date string to display only the date in local timezone
 * @param {string} dateString - The date string
 * @param {boolean|Object} utcOrAuditLog - Either a boolean for UTC or an audit log object for auto-detection
 * @returns {string} Formatted date string (e.g., "January 19, 2025")
 */
export const formatDateOnly = (dateString, utcOrAuditLog = false) => {
    if (!dateString) return '';

    try {
        // Determine if we should treat this as UTC
        const isUtc = typeof utcOrAuditLog === 'boolean'
            ? utcOrAuditLog
            : shouldTreatAsUtc(utcOrAuditLog);

        const date = new Date(isUtc ? dateString + 'Z' : dateString);
        if (isNaN(date.getTime())) return '';

        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    } catch (error) {
        console.error('Error formatting date:', error);
        return '';
    }
};

/**
 * Formats a date string to display date and time in local timezone
 * @param {string} dateString - The date string
 * @param {boolean|Object} utcOrAuditLog - Either a boolean for UTC or an audit log object for auto-detection
 * @returns {string} Formatted date and time string (e.g., "January 19, 2025 6:07 PM")
 */
export const formatDateTime = (dateString, utcOrAuditLog = false) => {
    if (!dateString) return '';

    try {
        // Determine if we should treat this as UTC
        const isUtc = typeof utcOrAuditLog === 'boolean'
            ? utcOrAuditLog
            : shouldTreatAsUtc(utcOrAuditLog);

        const date = new Date(isUtc ? dateString + 'Z' : dateString);
        if (isNaN(date.getTime())) return '';

        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    } catch (error) {
        console.error('Error formatting date and time:', error);
        return '';
    }
};

/**
 * Formats a date string to display date and time with "at" conjunction in local timezone
 * @param {string} dateString - The date string
 * @param {boolean|Object} utcOrAuditLog - Either a boolean for UTC or an audit log object for auto-detection
 * @returns {string} Formatted date and time string (e.g., "January 19, 2025 at 6:07 PM")
 */
export const formatDateTimeWithAt = (dateString, utcOrAuditLog = false) => {
    if (!dateString) return '';

    try {
        // Determine if we should treat this as UTC
        const isUtc = typeof utcOrAuditLog === 'boolean'
            ? utcOrAuditLog
            : shouldTreatAsUtc(utcOrAuditLog);

        const date = new Date(isUtc ? dateString + 'Z' : dateString);
        if (isNaN(date.getTime())) return '';

        const datePart = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        const timePart = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        });

        return `${datePart} at ${timePart}`;
    } catch (error) {
        console.error('Error formatting date and time with at:', error);
        return '';
    }
};

/**
 * Converts a date string to UTC format for sending to the server
 * @param {string} dateString - The date string in MM/DD/YYYY format
 * @returns {string|null} The date in UTC format or null if invalid
 */
export const formatDateForApi = (dateString) => {
    if (!dateString) return null;

    try {
        const [month, day, year] = dateString.split('/');
        if (!month || !day || !year) return null;

        const date = new Date(year, month - 1, day);
        if (isNaN(date.getTime())) return null;

        return date.toISOString().split('T')[0];
    } catch (error) {
        console.error('Error formatting date for API:', error);
        return null;
    }
};

/**
 * Formats a UTC date string from API to display format
 * @param {string} dateString - The UTC date string from API
 * @returns {string} Date in MM/DD/YYYY format
 */
export const formatDateFromApi = (dateString) => {
    if (!dateString) return '';

    try {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return '';

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    } catch (error) {
        console.error('Error formatting date from API:', error);
        return '';
    }
};
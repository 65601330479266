// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/Scheduler.js

import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import useFetchWithAuth from '../../../utils/useFetchWithAuth';
import { useMessage } from '../../../components/Message/MessageProvider';
import MonthView from './components/MonthView';
import WeekView from './components/WeekView';
import DayView from './components/DayView';
import AddVisitDialog from './components/AddVisitDialog';
import './Scheduler.css';

const Scheduler = () => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();

    const [currentDate, setCurrentDate] = useState(new Date());
    const [viewType, setViewType] = useState('month');
    const [visits, setVisits] = useState([]);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [showAddVisit, setShowAddVisit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userRoles, setUserRoles] = useState(null);

    const [dayViewScrollPosition, setDayViewScrollPosition] = useState(null);
    const [weekViewScrollPosition, setWeekViewScrollPosition] = useState(null);
    const [hasInitialScrollDay, setHasInitialScrollDay] = useState(false);
    const [hasInitialScrollWeek, setHasInitialScrollWeek] = useState(false);

    useEffect(() => {
        const fetchUserRoles = async () => {
            try {
                const response = await fetchWithAuth('/api/userroles');
                setUserRoles(response);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching user roles:', error);
                setUserRoles(null);
                setIsLoading(false);
                showMessage('error', 'Error loading user permissions', 3000);
            }
        };

        fetchUserRoles();
    }, [fetchWithAuth, showMessage]);

    useEffect(() => {
        fetchVisits();
    }, [currentDate, viewType]);

    const fetchVisits = async () => {
        try {
            setIsLoading(true);
            const response = await fetchWithAuth('/api/visit/all');

            if (!response) {
                throw new Error('No data received from server');
            }

            const processedVisits = (response.$values || response).map(visit => ({
                ...visit,
                visitId: visit.VisitId,
                patientId: visit.PatientId,
                subjectId: visit.SubjectId,
                patientName: visit.PatientName,
                subjectNumber: visit.SubjectNumber || null,
                protocolNumber: visit.ProtocolNumber || null,
                scheduledDateTime: new Date(visit.ScheduledDateTime),
                status: visit.Status,
                durationMinutes: visit.DurationMinutes,
                visitTemplateId: visit.VisitTemplateId,
                templateName: visit.TemplateName
            }));

            setVisits(processedVisits);
        } catch (error) {
            showMessage('error', `Error fetching visits: ${error.message || 'Unknown error'}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePrevious = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setMonth(date.getMonth() - 1);
                break;
            case 'week':
                date.setDate(date.getDate() - 7);
                break;
            case 'day':
                date.setDate(date.getDate() - 1);
                break;
            default:
                break;
        }
        setCurrentDate(date);
    };

    const handleNext = () => {
        const date = new Date(currentDate);
        switch (viewType) {
            case 'month':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'week':
                date.setDate(date.getDate() + 7);
                break;
            case 'day':
                date.setDate(date.getDate() + 1);
                break;
            default:
                break;
        }
        setCurrentDate(date);
    };

    const handleToday = () => {
        setCurrentDate(new Date());
    };

    const handleVisitClick = (visit) => {
        setSelectedVisit(visit);
        setShowAddVisit(true);
    };

    const handleVisitClose = () => {
        setSelectedVisit(null);
        setShowAddVisit(false);
    };

    const handleVisitSaved = async (visit) => {
        await fetchVisits();
        handleVisitClose();
    };

    const handleAddVisitClick = () => {
        setSelectedVisit(null);
        setShowAddVisit(true);
    };

    const changeViewType = (newViewType) => {
        if (viewType === 'week' && newViewType === 'day') {
            setDayViewScrollPosition(weekViewScrollPosition);
        }
        if (viewType === 'day' && newViewType === 'week') {
            setWeekViewScrollPosition(dayViewScrollPosition);
        }
        setViewType(newViewType);
    };

    const handleDayClick = (date) => {
        setCurrentDate(date);
        changeViewType('day');
    };

    const getViewTitle = () => {
        const options = { month: 'long', year: 'numeric' };
        switch (viewType) {
            case 'month':
                return currentDate.toLocaleDateString('en-US', options);
            case 'week': {
                const weekStart = new Date(currentDate);
                weekStart.setDate(weekStart.getDate() - weekStart.getDay());
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekEnd.getDate() + 6);
                return `${weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`;
            }
            case 'day':
                return currentDate.toLocaleDateString('en-US', { ...options, day: 'numeric' });
            default:
                return '';
        }
    };

    const renderView = () => {
        if (isLoading) {
            return <div className="loading-spinner">Loading...</div>;
        }

        const viewProps = {
            currentDate,
            visits,
            onVisitClick: handleVisitClick,
            isAdmin: userRoles?.IsAdmin || false
        };

        switch (viewType) {
            case 'month':
                return <MonthView {...viewProps} onDateClick={handleDayClick} />;
            case 'week':
                return (
                    <WeekView
                        {...viewProps}
                        initialScrollPosition={weekViewScrollPosition}
                        scrollToCurrentTimeOnInitialMount={!hasInitialScrollWeek}
                        onScrollChange={(pos) => {
                            setWeekViewScrollPosition(pos);
                            if (!hasInitialScrollWeek) {
                                setHasInitialScrollWeek(true);
                            }
                        }}
                        onDateClick={handleDayClick}
                    />
                );
            case 'day':
                return (
                    <DayView
                        {...viewProps}
                        initialScrollPosition={dayViewScrollPosition}
                        scrollToCurrentTimeOnInitialMount={!hasInitialScrollDay}
                        onScrollChange={(pos) => {
                            setDayViewScrollPosition(pos);
                            if (!hasInitialScrollDay) {
                                setHasInitialScrollDay(true);
                            }
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="scheduler-container">
            <div className="scheduler-header">
                <div className="scheduler-controls">
                    <div className="view-controls">
                        <button
                            className={`view-button ${viewType === 'month' ? 'active' : ''}`}
                            onClick={() => changeViewType('month')}
                        >
                            Month
                        </button>
                        <button
                            className={`view-button ${viewType === 'week' ? 'active' : ''}`}
                            onClick={() => changeViewType('week')}
                        >
                            Week
                        </button>
                        <button
                            className={`view-button ${viewType === 'day' ? 'active' : ''}`}
                            onClick={() => changeViewType('day')}
                        >
                            Day
                        </button>
                    </div>
                    <div className="navigation-controls">
                        <button className="nav-button" onClick={handleToday}>
                            Today
                        </button>
                        <button className="nav-button" onClick={handlePrevious}>
                            <ChevronLeft className="icon" />
                        </button>
                        <button className="nav-button" onClick={handleNext}>
                            <ChevronRight className="icon" />
                        </button>
                        <span className="current-view-title">{getViewTitle()}</span>
                    </div>
                    <button className="add-visit-button" onClick={handleAddVisitClick}>
                        <Plus size={20} />
                        New Visit
                    </button>
                </div>
            </div>

            <div className="scheduler-content">
                {renderView()}
            </div>

            {showAddVisit && (
                <AddVisitDialog
                    visit={selectedVisit}
                    onClose={handleVisitClose}
                    onSave={handleVisitSaved}
                    selectedDate={currentDate}
                    isAdmin={userRoles?.IsAdmin || false}
                />
            )}
        </div>
    );
};

export default Scheduler;
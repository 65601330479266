import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import {
    format,
    addMinutes,
    eachMinuteOfInterval,
    isToday,
    isSameDay,
    startOfWeek,
    addDays,
} from 'date-fns';
import { Clock, User } from 'lucide-react';

const WeekView = ({
    currentDate,
    visits,
    onVisitClick,
    initialScrollPosition,
    scrollToCurrentTimeOnInitialMount,
    onScrollChange,
    onDateClick,
}) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [linePosition, setLinePosition] = useState(null);
    const weekContentRef = useRef(null);
    const timeGridRef = useRef(null);
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);

    // --- BEGIN CUSTOMIZABLE CONCURRENCY ---
    const MAX_CONCURRENCY = 12;
    // --- END CUSTOMIZABLE CONCURRENCY ---

    const START_HOUR = 7;
    const END_HOUR = 19;

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 0 });
    const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

    const dayStart = new Date(currentDate);
    dayStart.setHours(START_HOUR, 0, 0, 0);
    const dayEnd = new Date(currentDate);
    dayEnd.setHours(END_HOUR, 0, 0, 0);

    const timeSlots = eachMinuteOfInterval({ start: dayStart, end: dayEnd }, { step: 15 });
    const totalMinutesInDay = timeSlots.length * 15;

    useEffect(() => {
        const handleTimeUpdate = () => {
            setCurrentTime(new Date());
        };
        const now = new Date();
        const msUntilNextMinute = (60 - now.getSeconds()) * 1000 - now.getMilliseconds();

        timeoutRef.current = setTimeout(() => {
            handleTimeUpdate();
            intervalRef.current = setInterval(handleTimeUpdate, 60000);
        }, msUntilNextMinute);

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);

    useLayoutEffect(() => {
        if (weekContentRef.current) {
            if (initialScrollPosition != null) {
                weekContentRef.current.scrollTop = initialScrollPosition;
                if (onScrollChange) {
                    onScrollChange(weekContentRef.current.scrollTop);
                }
            } else if (scrollToCurrentTimeOnInitialMount) {
                const now = new Date();
                const currentHour = now.getHours();
                if (currentHour >= START_HOUR && currentHour < END_HOUR) {
                    const minutesSinceStart = (currentHour - START_HOUR) * 60 + now.getMinutes();
                    const timeGridHeight =
                        weekContentRef.current.scrollHeight - weekContentRef.current.clientHeight;
                    const scrollPosition = (minutesSinceStart / totalMinutesInDay) * timeGridHeight;
                    weekContentRef.current.scrollTop = scrollPosition;
                    if (onScrollChange) {
                        onScrollChange(weekContentRef.current.scrollTop);
                    }
                }
            }
        }
    }, [
        initialScrollPosition,
        scrollToCurrentTimeOnInitialMount,
        onScrollChange,
        totalMinutesInDay,
    ]);

    useEffect(() => {
        const handleUserScroll = () => {
            if (weekContentRef.current && onScrollChange) {
                onScrollChange(weekContentRef.current.scrollTop);
            }
        };
        const weekContent = weekContentRef.current;
        if (weekContent) {
            weekContent.addEventListener('scroll', handleUserScroll);
        }
        return () => {
            if (weekContent) {
                weekContent.removeEventListener('scroll', handleUserScroll);
            }
        };
    }, [onScrollChange]);

    const isCurrentTimeSlot = (slotTime) => {
        const now = currentTime;
        return now >= slotTime && now < addMinutes(slotTime, 15);
    };

    const getDayVisits = (day) => {
        return visits.filter((visit) =>
            isSameDay(new Date(visit.scheduledDateTime), day)
        );
    };

    const getVisitsForSlot = (slot, dayVisits) => {
        return dayVisits.filter((visit) => {
            const visitDate = new Date(visit.scheduledDateTime);
            return (
                isSameDay(visitDate, slot) &&
                visitDate >= slot &&
                visitDate < addMinutes(slot, 15)
            );
        });
    };

    const calculateVisitPosition = (visit, dayVisits) => {
        const visitStart = new Date(visit.scheduledDateTime);
        const minutesSinceStart =
            (visitStart.getHours() - START_HOUR) * 60 + visitStart.getMinutes();

        const top = (minutesSinceStart / totalMinutesInDay) * 100;
        const height = (visit.durationMinutes / totalMinutesInDay) * 100;

        // Overlap check
        const visitEnd = addMinutes(visitStart, visit.durationMinutes);
        const overlappingVisits = dayVisits.filter((v) => {
            const vStart = new Date(v.scheduledDateTime);
            const vEnd = addMinutes(vStart, v.durationMinutes);
            return vStart < visitEnd && vEnd > visitStart;
        });

        const position = overlappingVisits.indexOf(visit);
        const concurrencyCount = Math.min(overlappingVisits.length, MAX_CONCURRENCY);

        const width = 100 / concurrencyCount;
        const left = position * width;

        return { top, height, width, left };
    };

    const getDisplayName = (visit) => {
        if (visit.patientName && visit.patientName.trim()) {
            return visit.patientName;
        } else if (visit.subjectNumber) {
            return visit.protocolNumber
                ? `${visit.subjectNumber}/${visit.protocolNumber}`
                : visit.subjectNumber;
        }
        return 'Unnamed Visit';
    };

    const renderVisit = (visit, dayVisits) => {
        const { top, height, width, left } = calculateVisitPosition(visit, dayVisits);
        const startTime = format(new Date(visit.scheduledDateTime), 'h:mm a');
        const endTime = format(
            addMinutes(new Date(visit.scheduledDateTime), visit.durationMinutes),
            'h:mm a'
        );
        const displayName = getDisplayName(visit);

        return (
            <div
                key={visit.visitId}
                className={`week-visit status-${visit.status.toLowerCase()} short-duration`}
                style={{ top: `${top}%`, height: `${height}%`, width: `${width}%`, left: `${left}%` }}
                onClick={() => onVisitClick(visit)}
                title={`${displayName} - ${startTime} - ${endTime}`}
            >
                <div className="visit-header">
                    <span className="month-visit-time">
                        {startTime} - {endTime}
                    </span>
                    <span className={`visit-status status-${visit.status.toLowerCase()}`}>
                        {visit.status}
                    </span>
                </div>
                <span className="visit-patient">
                    {displayName}
                </span>
            </div>
        );
    };

    useLayoutEffect(() => {
        const now = currentTime;
        const currentHour = now.getHours();

        if (currentHour >= START_HOUR && currentHour < END_HOUR && timeGridRef.current) {
            const minutesSinceStart = (currentHour - START_HOUR) * 60 + now.getMinutes();
            const timeGridHeight = timeGridRef.current.offsetHeight;
            const top = (minutesSinceStart / totalMinutesInDay) * timeGridHeight;
            setLinePosition(top);
        } else {
            setLinePosition(null);
        }
    }, [currentTime, totalMinutesInDay]);

    return (
        <div className="week-view">
            <div className="calendar-header">
                <div className="time-column-header"></div>
                {daysOfWeek.map((day) => (
                    <div
                        key={day.toString()}
                        className={`weekday-header${isToday(day) ? ' today' : ''}`}
                    >
                        <span className="weekday-full">{format(day, 'EEEE')}</span>
                        <span className="weekday-short">{format(day, 'EEE')}</span>
                        <div className="weekday-date">{format(day, 'MMM d')}</div>
                    </div>
                ))}
            </div>

            <div className="week-content" ref={weekContentRef} style={{ position: 'relative' }}>
                <div className="time-column">
                    {timeSlots.map((time) => (
                        <div
                            key={time.toString()}
                            className={`time-slot${isCurrentTimeSlot(time) ? ' current-timeslot' : ''
                                } ${format(time, 'mm') === '00' || format(time, 'mm') === '30'
                                    ? ' half-hour-line'
                                    : ''
                                }`}
                        >
                            <span
                                className={`time-label ${format(time, 'mm') === '00' ? 'hour-label' : 'minute-label'
                                    }`}
                            >
                                {format(time, 'h:mm a')}
                            </span>
                        </div>
                    ))}
                </div>

                {daysOfWeek.map((day, idx) => {
                    const dayVisits = getDayVisits(day);
                    return (
                        <div className={`day-column${isToday(day) ? ' today' : ''}`} key={day.toString()}>
                            <div className="time-grid" ref={idx === 0 ? timeGridRef : null}>
                                {timeSlots.map((slot) => (
                                    <React.Fragment key={slot.toString()}>
                                        <div
                                            className={`grid-line${isCurrentTimeSlot(slot) && isToday(day) ? ' current-timeslot' : ''
                                                }${format(slot, 'mm') === '00'
                                                    ? ' hour-line'
                                                    : format(slot, 'mm') === '30'
                                                        ? ' half-hour-line'
                                                        : ''
                                                }`}
                                            onClick={() => onDateClick(day)}
                                        >
                                            <div className="time-slot-overlay"></div>
                                        </div>
                                        {getVisitsForSlot(slot, dayVisits).map((visit) =>
                                            renderVisit(visit, dayVisits)
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    );
                })}

                {linePosition !== null && (
                    <div
                        className="current-time-line"
                        style={{
                            position: 'absolute',
                            left: '6rem',
                            right: '0',
                            top: `${linePosition}px`,
                            height: '2px',
                            backgroundColor: '#197FD0',
                            zIndex: '3',
                        }}
                    ></div>
                )}
            </div>
        </div>
    );
};

export default WeekView;

// Cerebro/ClientApp/src/pages/Dashboard/Scheduler/components/PatientSearch.js

import React, { useState, useEffect, useRef } from 'react';
import { Search, Loader } from 'lucide-react';
import useFetchWithAuth from '../../../../utils/useFetchWithAuth';
import { useMessage } from '../../../../components/Message/MessageProvider';
import { formatPhoneNumber } from '../../../../utils/formatters';
import './PatientSearch.css';

const PatientSearch = ({ onSelect, onModeChange }) => {
    const { fetchWithAuth } = useFetchWithAuth();
    const { showMessage } = useMessage();
    const [searchTerm, setSearchTerm] = useState('');
    const [searchMode, setSearchMode] = useState('patient'); // 'patient' or 'subject'
    const [searchType, setSearchType] = useState('lastName');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const searchRef = useRef(null);
    const searchTimeout = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        return () => {
            if (searchTimeout.current) {
                clearTimeout(searchTimeout.current);
            }
        };
    }, []);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setShowResults(true);

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        if (value.length >= 2) {
            searchTimeout.current = setTimeout(() => {
                performSearch(value);
            }, 300);
        } else {
            setSearchResults([]);
        }
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchTerm('');
        setSearchResults([]);
    };

    const handleSearchModeChange = (e) => {
        const newMode = e.target.value;
        setSearchMode(newMode);
        setSearchTerm('');
        setSearchResults([]);
        setSearchType(newMode === 'patient' ? 'lastName' : 'subjectNumber');
        onModeChange?.(newMode); 
    };

    const performSearch = async (term) => {
        if (!term) return;

        try {
            setLoading(true);
            let response;

            if (searchMode === 'patient') {
                response = await fetchWithAuth(
                    `/api/patient/search?searchTerm=${encodeURIComponent(term)}&searchType=${searchType}`
                );
            } else {
                response = await fetchWithAuth(
                    `/api/subject/search?searchTerm=${encodeURIComponent(term)}&searchType=${searchType}`
                );
            }

            setSearchResults(response.$values || []);
        } catch (error) {
            showMessage('error', `Error searching for ${searchMode}s`);
            console.error(`Error searching ${searchMode}s:`, error);
            setSearchResults([]);
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (result) => {
        onSelect({
            type: searchMode,
            data: result
        });
        setSearchTerm('');
        setSearchResults([]);
        setShowResults(false);
    };

    const renderSearchResults = () => {
        if (!showResults) return null;

        if (loading) {
            return (
                <div className="ps-search-results ps-search-results-loading">
                    <Loader className="ps-spinner" />
                    Searching...
                </div>
            );
        }

        if (searchResults.length === 0 && searchTerm.length >= 2) {
            return (
                <div className="ps-search-results ps-search-results-empty">
                    No {searchMode}s found
                </div>
            );
        }

        if (searchResults.length > 0) {
            return (
                <div className="ps-search-results">
                    {searchResults.map(result => (
                        <div
                            key={searchMode === 'patient' ? result.PatientId : result.SubjectId}
                            className="ps-search-result-item"
                            onClick={() => handleSelect(result)}
                        >
                            {searchMode === 'patient' ? (
                                <>
                                    <div className="ps-patient-primary">
                                        <span className="ps-patient-name">
                                            {result.FirstName} {result.LastName}
                                        </span>
                                        <span className="ps-patient-dob">
                                            {new Date(result.DateOfBirth).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <div className="ps-patient-secondary">
                                        {result.PhoneNumber && (
                                            <span className="ps-patient-phone">
                                                {formatPhoneNumber(result.PhoneNumber)}
                                            </span>
                                        )}
                                        {result.EmailAddress && (
                                            <span className="ps-patient-email">
                                                {result.EmailAddress}
                                            </span>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <div className="ps-subject-info">
                                    <div className="ps-subject-number">
                                        Subject Number: {result.SubjectNumber}
                                    </div>
                                    {result.ProtocolNumber && (
                                        <div className="ps-subject-protocol">
                                            Protocol Number: {result.ProtocolNumber}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <div className="ps-patient-search" ref={searchRef}>
            <div className="ps-search-container">
                <div className="ps-search-mode">
                    <select
                        value={searchMode}
                        onChange={handleSearchModeChange}
                        className="ps-search-mode-select"
                    >
                        <option value="patient">Search Patients</option>
                        <option value="subject">Search Subjects</option>
                    </select>
                </div>
                <div className="ps-search-type">
                    <select
                        value={searchType}
                        onChange={handleSearchTypeChange}
                        className="ps-search-type-select"
                    >
                        {searchMode === 'patient' ? (
                            <>
                                <option value="lastName">Last Name</option>
                                <option value="phone">Phone</option>
                                <option value="dob">Date of Birth</option>
                            </>
                        ) : (
                            <>
                                <option value="subjectNumber">Subject Number</option>
                                <option value="protocolNumber">Protocol Number</option>
                            </>
                        )}
                    </select>
                </div>
                <div className="ps-search-input-wrapper">
                    <input
                        type="text"
                        placeholder={`Search ${searchMode}s by ${searchType.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="ps-search-input"
                    />
                    {loading && <Loader className="ps-spinner" size={18} />}
                </div>
            </div>
            {renderSearchResults()}
        </div>
    );
};

export default PatientSearch;
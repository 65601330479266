import React, { useState } from 'react';
import { useMessage } from '../../../../../components/Message/MessageProvider';
import useFetchWithAuth from '../../../../../utils/useFetchWithAuth';
import backIcon from '../../../../../assets/icons/Back.png';  // Import back icon
import './AddProtocol.css';

const AddProtocol = ({ onClose, onSave }) => {
    const [protocolNumber, setProtocolNumber] = useState('');
    const { showMessage } = useMessage();
    const { fetchWithAuth } = useFetchWithAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!protocolNumber) {
            showMessage('error', 'Protocol Number is required.', 0, 0);
            return;
        }

        try {
            const responseData = await fetchWithAuth('/api/Protocol', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ protocolNumber })
            });

            if (responseData && responseData.ProtocolNumber) {
                showMessage('success', 'Protocol Number added successfully.', 0, 0, () => {
                    onSave();
                    onClose();
                });
            } else {
                showMessage('error', 'Failed to add protocol number.', 0, 0);
            }
        } catch (error) {
            showMessage('error', 'An error occurred while adding the protocol number.', 0, 0);
        }
    };

    const handleBackClick = () => {
        onClose();
    };

    return (
        <div className="add-protocol-popup">
            <img src={backIcon} alt="Back" className="back-icon" onClick={handleBackClick} />  {/* Add back icon */}
            <h2>Please enter a new Protocol Number</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={protocolNumber}
                    onChange={(e) => setProtocolNumber(e.target.value)}
                    required
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddProtocol;

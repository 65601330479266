// Cerebro/ClientApp/src/utils/useFetchWithAuth.js

const getToken = () => {
    return localStorage.getItem('auth_token');
};

const baseRequest = async (endpoint, options = {}) => {
    const baseURL = process.env.REACT_APP_API_URL || '';
    if (!baseURL) {
        throw new Error('Base URL is not set.');
    }
    const url = `${baseURL}${endpoint}`;
    const token = getToken();
    if (!token) {
        throw new Error('User is not authenticated');
    }
    const headers = {
        ...options.headers,
        'Authorization': `Bearer ${token}`,
    };
    try {
        const response = await fetch(url, {
            ...options,
            headers,
        });
        if (response.status === 304) {
            return null;
        }
        if (!response.ok) {
            const contentType = response.headers.get("content-type");
            let errorData;
            if (contentType && contentType.indexOf("application/json") !== -1) {
                errorData = await response.json();
            } else {
                errorData = { message: await response.text() };
            }
            if (errorData.errors) {
            }
            throw new Error(errorData.message || 'Failed to fetch');
        }
        const contentType = response.headers.get('content-type');

        if (contentType && (
            contentType.includes('application/octet-stream') ||
            contentType.includes('application/pdf') ||
            contentType.includes('image/') ||
            contentType.includes('application/msword') ||
            contentType.includes('application/vnd.openxmlformats-officedocument')
        )) {
            return await response.blob();
        }

        if (contentType && contentType.indexOf('application/json') !== -1) {
            return await response.json();
        } else {
            return await response.text();
        }
    } catch (error) {
        throw error;
    }
};

export const fetchWithAuth = async (endpoint, options = {}) => {
    return baseRequest(endpoint, options);
};

export const putWithAuth = async (endpoint, data, options = {}) => {
    try {
        const response = await baseRequest(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                ...options.headers,
            },
            body: JSON.stringify(data),
            ...options,
        });
        return response;
    } catch (error) {
        if (error.response) {
            const contentType = error.response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const errorData = await error.response.json();
                if (errorData.errors) {
                }
            } else {
                const errorText = await error.response.text();
            }
        }
        throw error;
    }
};

export const uploadWithProgressAuth = (endpoint, formData, onProgress, method = 'POST') => {
    return new Promise((resolve, reject) => {
        const baseURL = process.env.REACT_APP_API_URL || '';
        if (!baseURL) {
            return reject(new Error('Base URL is not set.'));
        }
        const url = `${baseURL}${endpoint}`;
        const token = getToken();
        if (!token) {
            return reject(new Error('User is not authenticated'));
        }

        const xhr = new XMLHttpRequest();
        xhr.open(method, url, true);

        // Set the auth header
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        // Track upload progress
        if (xhr.upload && typeof onProgress === 'function') {
            xhr.upload.onprogress = function (event) {
                if (event.lengthComputable) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    onProgress(percent); // pass progress up to the caller
                }
            };
        }

        // Handle the response
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                // We need to parse the response according to content-type
                const contentType = xhr.getResponseHeader('content-type') || '';

                if (
                    contentType.includes('application/octet-stream') ||
                    contentType.includes('application/pdf') ||
                    contentType.includes('image/') ||
                    contentType.includes('application/msword') ||
                    contentType.includes('application/vnd.openxmlformats-officedocument')
                ) {
                    // Construct a Blob from XHR response
                    return resolve(new Blob([xhr.response]));
                }

                if (contentType.includes('application/json')) {
                    try {
                        const json = JSON.parse(xhr.responseText);
                        return resolve(json);
                    } catch (err) {
                        return reject(new Error('Failed to parse JSON response'));
                    }
                }

                // Fallback: just return the raw text
                return resolve(xhr.responseText);
            } else {
                // XHR error -> parse or fallback
                let errorText;
                try {
                    // Attempt to parse JSON error
                    errorText = JSON.parse(xhr.responseText)?.message || xhr.responseText;
                } catch {
                    errorText = xhr.responseText || 'Error uploading file';
                }
                return reject(new Error(errorText));
            }
        };

        xhr.onerror = function () {
            return reject(new Error('Network error or CORS issue.'));
        };

        xhr.send(formData);
    });
};

export const useFetchWithAuth = () => {
    return { fetchWithAuth, putWithAuth };
};

export default useFetchWithAuth;